<template>
  <div class="dg-module-form" v-if="amReady">
    <div class="title" v-if="formHeader">{{ this.getFormTitle }}</div>
   <!-- <el-row>
      <el-transfer
          filterable
          :filter-method="filterMethod"
          filter-placeholder="State Abbreviations"
          v-model="value"
          :data="data">
      </el-transfer>
    </el-row>-->

    <el-row>
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 8 : 8">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

            :field_span="19"
            :label_span="24"
            :translateLabel='false'
            field_mark="required"
            label="Name"
            name="name"
            placeholder="Name e.g customer-rate"
            rules="required"></dg-text-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 8 : 8">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

            :field_span="19"
            :label_span="24"
            :translateLabel='false'
            field_mark="required"
            label="Namespace"
            name="namespace"
            placeholder="Namespace e.g voip"
            rules="required"></dg-text-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 8 : 8">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

            :field_span="19"
            :label_span="24"
            :translateLabel='false'
            field_mark="required"
            label="Caption"
            name="caption"
            placeholder="Caption e.g voip"
            rules="required"></dg-text-field>
      </el-col>
    </el-row>
    <el-row>
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 8 : 8">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

            :field_span="19"
            :label_span="24"
            :translateLabel='false'
            field_mark="required"
            label="Group Name"
            name="group_name"
            placeholder="Users"
            rules="required"></dg-text-field>
      </el-col>

      <!--<el-col :class="$langConfig.cssClass" :span="(!editMode)? 8 : 8">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

            :field_span="19"
            :label_span="24"
            :options="$store.state['Root-AppModules-api'].dropDownList"
            :translateLabel='false'
            :translateLabels="false"
            field_mark="optional"
            label="destination"
            name="destination"
            placeholder=""
            rules="optional"></dg-select-field>
      </el-col>-->

      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 8 : 8">
        <dg-radio-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="19"
          :label_span="24"
          :options="radioOptions"
          :translateLabel="false"
          defaultValue="on"
          field_mark="required"
          label="Used By Root"
          name="used_by_root"
          rules="required"></dg-radio-field>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24" class="mt-2 border-top">
        <el-col :class="$langConfig.cssClass" :span="12" class="mt-4">
          <label class="error-field-validate-message"
                 v-show="!this.validData">{{ (requestErrorMessage) ? requestErrorMessage : $ml.get('fields_do_not_match_the_required_criteria') }}</label>
        </el-col>
        <el-col :span="12" class="pt-3 text-right">
          <dg-confirm-button
              :loading="requestOn"
              :onConfirm="moduleDeleteAction"
              :translate="false"
              style="margin-right: 10px"
              confirmButtonType="danger"
              fieldSize="mini"
              icon="el-icon-delete"
              popIcon="el-icon-delete"
              text="remove"
              title="sure to removing this record ?"
              type="danger"
              v-if="editMode"></dg-confirm-button>

          <dg-button :click="cancelEditable" class="mr-2" fieldSize="mini" icon="el-icon-close" text="cancel"
                     type="warning" v-if="editable && editMode"></dg-button>
          <dg-button :click="changeEditable" fieldSize="mini" icon="el-icon-edit" text="edit" type="success"
                     v-if="!editable && editMode"></dg-button>

          <dg-button :click="createModule" :icon="actionButtonIcon" :loading="requestOn" :text="actionButtonLabel"
                     :translate="false"
                     :type="actionButtonType" fieldSize="mini"
                     v-if="editable"></dg-button>

          <!--<dg-confirm-button
              :icon="actionButtonIcon"
              :loading="requestOn"
              :onConfirm="createModule"
              :text="actionButtonLabel"
              :translate="false"
              :type="actionButtonType"
              fieldSize="mini"
              v-if="editable"></dg-confirm-button>-->
        </el-col>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import module from './../index.js'

import {notify, success, danger} from '@/system-services/notification/notify.js'

import fieldController from 'devegram-vue-data-collecter-eui/src/mixins/fieldController';
export default {
  beforeMount() {this.getFormData()},
  data() {
    /*const generateData = _ => {
      const data = [];
      const states = ['California', 'Illinois', 'Maryland', 'Texas', 'Florida', 'Colorado', 'Connecticut '];
      const initials = ['CA', 'IL', 'MD', 'TX', 'FL', 'CO', 'CT'];
      states.forEach((city, index) => {
        data.push({
          label: city,
          key: index,
          initial: initials[index]
        });
      });
      return data;
    };*/

    return {
      /*value: [],
      data: generateData(),*/
      module: {...module},
      control: this,
      amReady: false,
      requestOn: false,
      editable: !this.editMode,
      requestErrorMessage: null,
      radioOptions: [
        {
          label: 'Online',
          value: 'on',
          link_label: ''
        },
        {
          label: 'Offline',
          value: 'off',
          link_label: ''
        }
      ],
    }
  },
  methods: {
    filterMethod(query, item) {
      return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    getFormData() {
      let promises = []
      promises.push(this.getEntityDropDownList().then())
      Promise.all(promises).then((values) => {
        this.amReady = true
        $vue.nextTick(() => {this.bindController()})
      })
    },
    ...$mapActions('Root-AppModules-api', {
      getEntityDropDownList: 'getDropDownList',
    }),
    /* Images Methods */
    async createModule() {
      this.validateFields().then( async result => {
        if (!result) { this.requestErrorMessage = null; return}

        this.requestOn = true
        let context = this;
        let apiMethod = (this.editMode) ? 'update' : 'create';
        let apiParams = {data: {...this.dataModel}};
        if (this.editMode) apiParams.id = this.dataToBind.id;

        this.$store.dispatch('Root-AppModules-api/' + apiMethod, apiParams).then(response => {
          let entity = response.data
          this.amReady = false
          success((apiMethod == 'update')? 'Updated' : 'Created')

          $vue.nextTick(() => {
            context.amReady = true
            if (context.editMode) {
              if (context.onEditCallback) context.onEditCallback(response.data)
            } else {
              if (context.onCreateCallback) context.onCreateCallback(response.data)
            }
          })

        }).catch(error => {
          console.log('catch error', error)
          this.validData = false
          if (error.response.data.errorMessage) {
            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {this.requestOn = false})
      })
    }
  },
  mixins: [fieldController, $mixins['moduleForm']],
}
</script>
